import React, { FC, useEffect, useState } from "react";

import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import * as styles from "./styles.module.scss";

import CustomButton from "~components/common/customButton";
import CircleLoading from "~components/loading/circleLoading";
import GeneralEditModal from "~components/modalLowres/generalEditModal";
import { useModalValue } from "~context/ModalContext";
import { isAndroid, isIOS } from "~lib/deviceDetect";
import gatsbyImageMap from "~lib/gatsbyImageMap";
import { NOT_LOG_DOWNLOAD_APP_MODAL } from "~lib/keyNamesOfLocalStorage";
import { NOT_SHOW_DOWNLOAD_APP_MODAL } from "~lib/keyNamesOfSessionStorage";
import { logUserInteraction } from "~lib/log-page-views";
import { openMobileLink } from "~lib/mobileLinking";
import { APPLE_STORE_URL, GOOGLE_PLAY_URL } from "~lib/staticUrlConstants";

const DownloadAppModal: FC = () => {
  const [fadeOut, setFadeOut] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMobileLogin, setIsMobileLogin] = useState<boolean>(false);
  const modalContext = useModalValue();

  const notShowDownloadAppModalAgain = sessionStorage.getItem(
    NOT_SHOW_DOWNLOAD_APP_MODAL
  );

  const notLogDownloadAppModalAgain = localStorage.getItem(
    NOT_LOG_DOWNLOAD_APP_MODAL
  );

  const data = useStaticQuery(
    graphql`
      query {
        mobileImage: allFile(
          filter: {
            extension: { regex: "/png/" }
            relativeDirectory: { eq: "downloadAppModal" }
          }
        ) {
          edges {
            node {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 300)
              }
              id
              name
            }
          }
        }
      }
    `
  );
  const { mobileImage } = data;
  const imageMap = gatsbyImageMap(mobileImage);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 3000);
    modalIsVisible &&
      modalContext.showModal(DownloadAppModal, { isModalVisible: true });
    const isMobileLoginPage =
      window.location.pathname.includes("/login/mobile") ||
      window.location.pathname.includes("/indeed/oauth");
    setIsMobileLogin(isMobileLoginPage);

    !notLogDownloadAppModalAgain &&
      !isMobileLoginPage &&
      logUserInteraction({
        category: "download_app_modal",
        action: "action",
        label: "group-2"
      });
    localStorage.setItem(NOT_LOG_DOWNLOAD_APP_MODAL, "true");

    const timer = setTimeout(() => {
      setFadeOut(false);
    }, 7000);

    return () => clearTimeout(timer);
  }, []);

  const closeModal = (): void => {
    setShowModal(false);
    modalContext.hideModal();
    sessionStorage.setItem(NOT_SHOW_DOWNLOAD_APP_MODAL, "true");
  };

  const goToAppStore = (): void => {
    if (isIOS) {
      window.location.href = APPLE_STORE_URL;
    } else if (isAndroid) {
      window.location.href = GOOGLE_PLAY_URL;
    }
  };

  const openApp = async (): Promise<void> => {
    setLoading(true);
    logUserInteraction({
      category: "download_app_modal",
      action: "click",
      label: "clicked-version-2"
    });
    const initialTime = Date.now();
    await openMobileLink("/web2app");
    const checkOpen = setInterval(async () => {
      const waitTime = Date.now() - initialTime;
      setLoading(false);
      closeModal();
      if (waitTime > 1000) {
        clearInterval(checkOpen);
      } else {
        goToAppStore();
      }
    }, 500);
  };

  const modalIsVisible =
    !isMobileLogin && !notShowDownloadAppModalAgain && showModal;

  return (
    modalIsVisible && (
      <GeneralEditModal
        id="webToAppModal"
        isOpen={true}
        btnConfirm={<></>}
        btnCancel={<></>}
        modalWrapper={styles.modalWrapper}
        modalOverlay={styles.modalOverlay}
        ariaLabel="Download app modal"
      >
        <GatsbyImage
          image={imageMap["download-app-thumbs-up"]}
          className={styles.downloadAppIcon}
          alt="download app"
        />
        {fadeOut && (
          <GatsbyImage
            image={imageMap["auto-click"]}
            className={`${styles.autoClickIcon} ${styles.slideFade}`}
            alt="click this button"
          />
        )}
        <p className={styles.downloadAppText}>
          Creating a resume is hard Our app makes it easy
        </p>
        <CustomButton
          aria-label="go to the app"
          element="goToAppBtn"
          disabled={loading}
          className={`btn btn-primary-blue ${styles.downloadAppBtn}`}
          data-heap-id="crb-web-to-app-modal--open-app-click"
          data-heap-abtest-id="modal-version-B"
          onClick={() => {
            openApp();
          }}
        >
          {loading ? <CircleLoading /> : "Go to The App"}
        </CustomButton>
        <CustomButton
          className={styles.closeBtn}
          aria-label="close the modal"
          data-heap-id="crb-web-to-app-modal--close-app-click"
          data-heap-abtest-id="modal-version-B"
          onClick={() => {
            closeModal();
          }}
        >
          Not Now
        </CustomButton>
      </GeneralEditModal>
    )
  );
};

export default DownloadAppModal;
